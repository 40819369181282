import React, { useContext } from 'react';

import { Link } from 'gatsby';

import { MenuContext } from '../../context';

const GeneralAnchor = (
  {
    children,
    isBlank,
    href,
    isLink = false,
    target,
    ariaLabel = '',
    referrer,
    isRed,
    downloadTitle,
    onClick,
    additionalClass,
    noClass = false,
    isTealiumLink
  }) => {
  const menuContext = useContext(MenuContext);

  return (
    <>
      {isLink === true ? (
        <a
          className={`${(additionalClass && additionalClass) || ''}${(noClass !== true && ' general-anchor-btn') || ''}${(isRed && ' general-anchor-btn--red') || ''}`}
          href={href}
          target={target ? target : isBlank ? '_blank' : '_self'}
          rel={referrer ? referrer : undefined}
          download={downloadTitle}
          aria-label={ariaLabel}
          onClick={(e) => onClick && onClick(e)}
          inert={menuContext.isMenuOpen ? 'true' : null}
          {...(isTealiumLink) && { 'data-tealium': '' }}
        >
          {children}
        </a>
      ) : (
        <Link
          className={`${(additionalClass && additionalClass) || ''}${(noClass !== true && ' general-anchor-btn') || ''}${(isRed && ' general-anchor-btn--red') || ''}`}
          to={href}
          target={target ? target : isBlank ? '_blank' : '_self'}
          rel={referrer ? referrer : undefined}
          download={downloadTitle}
          aria-label={ariaLabel}
          onClick={(e) => onClick && onClick(e)}
          inert={menuContext.isMenuOpen ? 'true' : null}
        >
          {children}
        </Link>
      )}
    </>
  );
};

export default GeneralAnchor;